// Imports
import React, { useState, useEffect, useContext } from 'react';
import { myContext } from '../components/Provider';

// Components
import Layout from '../components/Layout';
import ProductContainer from '../components/ProductContainer';

// ======== Component ========
const Search = () => {
	// Grab React context value and store it in state
	const contextValue = useContext(myContext);

	// Search state
	const [searchedData, setSearchedData] = useState(null);

	// Filter the data based on searched term
	const filterData = (dataArr, searchVal) => {
		const newData = dataArr.filter(obj => {
			const title = obj.uk_title.toLowerCase();
			const desc = obj.uk_description.toLowerCase();
			return (title.includes(searchVal.toLowerCase())
				|| desc.includes(searchVal.toLowerCase()));
		});
		return newData;
	};

	// Searches
	useEffect(() => {
		if (!contextValue) return;
		setSearchedData(filterData(contextValue.data.data, contextValue.searchValue));
	}, [contextValue]);

	// Handle search
	const handleSearch = val => {
		contextValue.setSearchValue(val);
	};

	return (
		<Layout title={'Search | Balanced Body'}>
			{contextValue
				? (
					<ProductContainer
						productData={searchedData}
						categoryName={`Search: ${ contextValue.searchValue }`}
						setSearchVal={handleSearch}
					/>
				)
				: (
					<ProductContainer
						productData={searchedData}
						categoryName={'Search:'}
						setSearchVal={handleSearch}
					/>
				)}

		</Layout>
	);
};

export default Search;
